import { Component, Inject, OnInit } from "@angular/core";
import { Product, ProductBreezeModel } from "@common/ADAPT.Common.Model/organisation/product";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { IDxCustomRuleValidationCallbackEvent } from "@common/ux/dx.types";
import { ProductCatalogueService } from "app/features/product/product-catalogue/product-catalogue.service";
import { catchError, tap } from "rxjs/operators";

@Component({
    selector: "adapt-edit-product-dialog",
    templateUrl: "./edit-product-dialog.component.html",
})
export class EditProductDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Product> implements OnInit {
    public readonly dialogName = "EditGuidanceMaterialDialog";

    public title = "";
    public saveError?: string;

    private products: Product[] = [];

    constructor(
        @Inject(ADAPT_DIALOG_DATA) public product: Product,
        private productCatalogueService: ProductCatalogueService,
    ) {
        super();
        this.autoResolveData = product;
    }

    public get entitiesToConfirm() {
        return [this.product];
    }

    public get isValid() {
        return this.product.name
            && !this.product.entityAspect.hasValidationErrors
            && this.validateDuplicates({ value: this.product.name });
    }

    public ngOnInit() {
        const addMode = this.product.entityAspect.entityState.isAdded();
        const actionText = addMode
            ? "Create "
            : "Edit ";
        this.title = actionText + ProductBreezeModel.singularName.toLowerCase();

        this.productCatalogueService.getProducts().pipe(
            this.takeUntilDestroyed(),
        ).subscribe((products) => this.products = products);
    }

    @Autobind
    public validateDuplicates({ value }: IDxCustomRuleValidationCallbackEvent) {
        if (typeof value === "string") {
            const labelExists = this.products
                .filter((product) => product !== this.product)
                .some((product) => product.name.trim().toLowerCase() === value.trim().toLowerCase());
            return !labelExists;
        }
        return false;
    }

    @Autobind
    public saveAndClose() {
        return this.productCatalogueService.saveEntities(this.product).pipe(
            tap(() => this.resolve(this.product)),
            catchError((e) => this.saveError = ErrorHandlingUtilities.getHttpResponseMessage(e)),
        );
    }
}
