import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { IConfigGroup } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationAuthService } from "@org-common/lib/configuration/configuration-auth.service";
import { ConfigArea } from "@org-common/lib/configuration/configuration-types";

export const allOrganisationConfigGroups = (): IConfigGroup[] => [
    {
        key: "System",
        items: [
            {
                name: "Organisation profile",
                area: ConfigArea.OrganisationProfile,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
            },
            {
                name: "External links",
                area: ConfigArea.ExternalLink,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.OrganisationExternalDashboard,
                enableFeatureText: "external links",
            },
            {
                name: "Personal profile categories",
                area: ConfigArea.PersonalProfileCategories,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
            },
            {
                name: "Billing",
                area: ConfigArea.Billing,
                accessVerifier: "configureOrganisationBilling",
            },
            {
                name: "Email customisation",
                area: ConfigArea.CustomWelcomeEmail,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
            },
            {
                name: "Calendar integration",
                area: ConfigArea.CalendarIntegration,
            },
            {
                name: "Developer API",
                area: ConfigArea.Api,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.OrganisationApi,
                enableFeatureText: "developer API",
            },
            // {
            //     name: "Feature flags",
            //     area: ConfigArea.FeatureFlags,
            //     accessVerifier: ConfigurationAuthService.StakeholderManagerAccess,
            // },
        ],
    },
    {
        key: "Organisation",
        items: [
            {
                name: "Purpose & vision",
                area: ConfigArea.PurposeAndVision,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.PurposeAndVision,
                enableFeatureText: "purpose & vision",
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.ValuePurposeVision),
                supportText: "Find out more about purpose and vision in the ADAPT Method",
            },
            {
                name: "Resilient business goals",
                area: ConfigArea.ResilientBusinessGoals,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.CompassResilientBusinessGoals,
                enableFeatureText: "resilient business goals",
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.ResilientBusinessGoals),
                supportText: "Find out more about resilient business goals in the ADAPT Method",
            },
            {
                name: "Objectives & key results",
                area: ConfigArea.ObjectivesAndKeyResults,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.StewardshipObjectives,
                enableFeatureText: "objectives & key results",
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.IntroducingObjectivesAndKeyResults),
                supportText: "Find out more about objectives in the ADAPT Method",
            },
            {
                name: "Resilient business assessment",
                area: ConfigArea.ResilientBusinessAssessment,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.CulturalNetworkResilientBusinessAssessment,
                enableFeatureText: "resilient business assessment",
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.ResilientBusinessAssessment),
                supportText: "Find out more about resilient business assessment in the ADAPT Method",
            },
            {
                name: "Bullseye",
                area: ConfigArea.Bullseye,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.Bullseye,
                enableFeatureText: "bullseye",
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.BullseyeOverview),
                supportText: "Find out more about bullseye in ADAPT Method",
            },
            {
                name: "Strategic goals",
                area: ConfigArea.StrategicGoals,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.StrategicGoals,
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.StrategicGoalsOverviewEmbed),
                supportText: "Find out more about strategic goals in ADAPT Method",
            },
            {
                name: "Strategic anchors",
                area: ConfigArea.StrategicAnchors,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.StrategicAnchors,
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.StrategicAnchorsOverview),
                supportText: "Find out more about strategic anchors in ADAPT Method",
            },
            {
                name: "Teams",
                area: ConfigArea.Team,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.UsingTheAdaptTeamModel),
                supportText: "Find out more about teams in the ADAPT Method",
            },
            {
                name: "Actions",
                area: ConfigArea.Kanban,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.StewardshipWorkKanban,
                enableFeatureText: "actions",
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.UsingKanbanBoards),
                supportText: "Find out more about effective team stewardship in the ADAPT Method",
            },
            {
                name: "Meetings",
                area: ConfigArea.Meetings,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.StewardshipWorkMeetings,
                enableFeatureText: "meetings",
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.UsingKanbanBoards),
                supportText: "Find out more about effective team stewardship in the ADAPT Method",
            },
            {
                name: "Labels",
                area: ConfigArea.Labels,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.LabelsEmbed),
                supportText: "Find out more about effective use of labels in the ADAPT Method",
            },
        ],
    },
    {
        key: "Economic engine",
        items: [
            {
                name: "Stakeholders",
                area: ConfigArea.Stakeholders,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.StakeholdersPeople,
                enableFeatureText: "stakeholders",
                supportText: "Find out more about stakeholders in the ADAPT Method",
            },
        ],
    },
    {
        key: "Leadership & culture",
        items: [
            {
                name: "Values",
                area: ConfigArea.ValuesConstitution,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.CompassValuesConstitution,
                enableFeatureText: "values",
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.ValuePurposeVision),
                supportText: "Find out more about values and value constitutions inside the ADAPT Method",
            },
            {
                name: "Cultural Index",
                area: ConfigArea.CulturalIndex,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.CulturalNetworkCulturalIndex,
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.CulturalIndex),
                supportText: "Find out more about the cultural index in the ADAPT Method",
            },
            {
                name: "Career Valuation",
                area: ConfigArea.CareerValuation,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.CulturalNetworkCareerValuation,
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.CareerValuation),
                supportText: "Find out more about career valuations in the ADAPT Method",
            },
            {
                name: "Cultural Leadership Framework",
                area: ConfigArea.CulturalLeadership,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.CulturalNetworkCulturalLeadershipCohort,
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.BuildCulturalLeadership),
                supportText: "Find out more about cultural leadership in the ADAPT method",
            },
            {
                name: "Peer Catch-up",
                area: ConfigArea.PeerCatchUp,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.PeoplePeerCatchUp,
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.PeerCatchUp),
                supportText: "Find out more about Peer Catch-ups in the ADAPT Method",
            },
            {
                name: "Employee engagement",
                area: ConfigArea.EmployeeEngagement,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.CulturalNetworkEmployeeEngagement,
                enableFeatureText: "employee engagement",
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.EmployeeEngagementSurvey),
                supportText: "Find out more about employee engagement in the ADAPT Method",
            },
            {
                name: "Team assessment",
                area: ConfigArea.TeamAssessment,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.CulturalNetworkTeamAssessment,
                enableFeatureText: "team assessment",
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.UsingTheAdaptTeamModel),
                supportText: "Find out more about team assessment in the ADAPT Method",
            },
        ],
    },
    {
        key: "Organisation design",
        items: [
            {
                name: "Tier 1 architecture",
                area: ConfigArea.Tier1,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.Architecture),
                supportText: "Find out more about tier 1 architecture and value streams in the ADAPT Method",
            },
            {
                name: "Systems",
                area: ConfigArea.Tier2,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.ArchitectureTier2,
                enableFeatureText: "systems",
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.SystemiseYourBusiness),
                supportText: "Find out more about Systems & processes (systems, roles & guidance materials) in the ADAPT Method",
            },
        ],
    },
];
