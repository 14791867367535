<adapt-dialog [maxWidth]="700">
    <h3 adapt-dialog-title>{{title}}</h3>

    <ng-container adapt-dialog-content>
        <div class="alert alert-guidance">
            <p>
                Guidance materials are documents, videos, wiki pages, etc that are stored externally
                to embedADAPT but may be useful to guide people on how to perform tasks in the organisation.
            </p>
            <p>
                Enter the name of the guidance material and the url to access it below.
            </p>
            <p>
                <strong>NB: The guidance material needs to be stored in a location accessible via your web browser.</strong>
            </p>
        </div>

        <div class="form-group">
            <label>Name</label>
            <dx-text-box [(value)]="dialogData.guidanceMaterial.name"
                         [adaptValidateEntity]="dialogData.guidanceMaterial"
                         adaptValidateEntityProperty="name"
                         placeholder="Enter guidance material name"
                         data-test="guidance-material-name-input"></dx-text-box>
        </div>

        <div class="form-group">
            <label>Link</label>
            <adapt-select-document [(url)]="dialogData.guidanceMaterial.locationUrl"
                                   data-test="guidance-material-link-input"></adapt-select-document>
        </div>

        <div class="form-group">
            <label>Description</label>
            <adapt-html-editor [(contents)]="dialogData.guidanceMaterial.description"></adapt-html-editor>
        </div>

        <div role="alert"
             class="alert alert-danger"
             *ngIf="saveError">
            {{saveError}}
        </div>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="dialogData.guidanceMaterial.entityAspect.hasValidationErrors"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
