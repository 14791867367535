import { Component, Inject, ViewChild } from "@angular/core";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { EditKeyFunctionDetailsComponent } from "../edit-key-function-details/edit-key-function-details.component";

@Component({
    selector: "adapt-add-key-function-dialog",
    templateUrl: "./add-key-function-dialog.component.html",
})
export class AddKeyFunctionDialogComponent extends BaseDialogWithDiscardConfirmationComponent<KeyFunction> {
    public readonly dialogName = "AddKeyFunction";
    protected readonly autoResolveData = this.keyFunction;

    @ViewChild(EditKeyFunctionDetailsComponent)
    public keyFunctionDetailsComponent?: EditKeyFunctionDetailsComponent;

    public entitiesToConfirm: IBreezeEntity[];

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public keyFunction: KeyFunction,
    ) {
        super();

        if (!keyFunction.supplementaryData) {
            throw new Error("KeyFunctionSupplementaryData should be created before opening the dialog");
        }
        if (!keyFunction.leaderRole) {
            throw new Error("Key Function leader role should be created before opening the dialog");
        }

        this.entitiesToConfirm = [
            keyFunction,
            keyFunction.supplementaryData,
            keyFunction.leaderRole,
        ];
    }

    public get purpose() {
        return this.keyFunction.supplementaryData?.purpose;
    }

    public set purpose(value: string | undefined) {
        if (this.keyFunction.supplementaryData) {
            this.keyFunction.supplementaryData.purpose = value;
        }
    }

    public addEntitiesToConfirm(entities: IBreezeEntity | IBreezeEntity[]) {
        if (!Array.isArray(entities)) {
            entities = [entities];
        }

        this.entitiesToConfirm.push(...entities);
        this.entitiesToConfirm = ArrayUtilities.distinct(this.entitiesToConfirm);
    }
}
