import { Component, Inject, Injector, OnInit, ViewChild } from "@angular/core";
import { Purpose } from "@common/ADAPT.Common.Model/organisation/purpose";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { ValueStream } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { CommonPurposeVisionService } from "@org-common/lib/purpose-vision/common-purpose-vision.service";
import { AllocateTier1RoleComponent } from "app/features/architecture/role/allocate-tier1-role/allocate-tier1-role.component";
import { lastValueFrom, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { Tier1ArchitectureService } from "../../tier1-architecture/tier1-architecture.service";

@Component({
    selector: "adapt-edit-value-stream-dialog",
    templateUrl: "./edit-value-stream-dialog.component.html",
})
export class EditValueStreamDialogComponent extends BaseDialogWithDiscardConfirmationComponent<ValueStream> implements OnInit {
    public readonly dialogName = "EditValueStream";

    public vsTslRole?: Role;
    public vsPurpose?: Purpose;

    @ViewChild(AllocateTier1RoleComponent)
    private allocateRoleComponent!: AllocateTier1RoleComponent;

    public constructor(
        injector: Injector,
        @Inject(ADAPT_DIALOG_DATA) public valueStream: ValueStream,
        private tier1Service: Tier1ArchitectureService,
        private purposeVisionService: CommonPurposeVisionService,
    ) {
        super(injector);

        this.autoResolveData = this.valueStream;
    }

    public get entitiesToConfirm() {
        const entities: IBreezeEntity[] = [this.valueStream];

        if (this.vsTslRole) {
            entities.push(this.vsTslRole);
        }
        if (this.vsPurpose) {
            entities.push(this.vsPurpose);
        }

        return ArrayUtilities.distinct(entities.concat(this.allocateRoleComponent.modifiedEntities));
    }

    public async ngOnInit() {
        [this.vsTslRole, this.vsPurpose] = await Promise.all([
            lastValueFrom(this.tier1Service.getLeaderRoleForValueStreamId(this.valueStream.valueStreamId)),
            lastValueFrom(this.purposeVisionService.getOrCreatePurpose(this.valueStream)),
        ]);
    }

    public updateRelatedEntities() {
        if (this.vsTslRole && this.vsTslRole.roleType) {
            this.vsTslRole.label = this.tier1Service.getRoleLabelForValueStream(this.vsTslRole.roleType, this.valueStream);
        }
    }

    @Autobind
    public saveAndClose() {
        return this.tier1Service.getValueStreamByName(this.valueStream.name, this.valueStream.valueStreamId).pipe(
            switchMap((existingVsWithName) => existingVsWithName
                ? this.commonDialogService.showErrorDialog("Duplicate Value Stream", "A value stream with the same name already exists.", "OK")
                : of({})),
            switchMap(() => super.saveAndClose()),
        );
    }
}
