<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>Manage value streams</h3>

    <div adapt-dialog-content
         *adaptLoading="!valueStreams">
        <dx-list [dataSource]="valueStreams"
                 [hoverStateEnabled]="false"
                 [focusStateEnabled]="false"
                 [activeStateEnabled]="false"
                 displayExpr="name"
                 height="auto"
                 (onItemReordered)="updateOrdinals($event)">
            <dxo-item-dragging [allowReordering]="true"></dxo-item-dragging>
        </dx-list>
        <hr />
        <h3 class="mt-4">Add a new value stream</h3>
        <div class="d-flex">
            <dx-text-box class="flex-grow-1 me-2"
                         [(value)]="newValueStreamName"
                         placeholder="Type a new value stream name"
                         data-test="value-stream-name-input"></dx-text-box>
            <button adaptButton="add"
                    [disabled]="!newValueStreamName"
                    [adaptBlockingClick]="createAndAddValueStream"
                    data-test="add-value-stream-button"></button>
        </div>

        <div class="mt-2 mb-0 alert alert-info">
            To archive or delete a value stream, please speak to your Resilient Business Coach
            or get in touch with the ADAPT Tech team using Intercom (the chat icon at the bottom
            right of your screen).
        </div>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [discardConfirmationInstance]="instance"></adapt-standard-dialog-buttons>
</adapt-dialog>
