import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { EntityExtensions } from "../entity-extensions.decorator";
import { Person } from "../person/person";
import { CulturalIndexExtensions } from "./cultural-index-extensions";
import { ICulturalMeasurementEntity } from "./culturalMeasurementEntity.interface";
import { Organisation } from "./organisation";

export enum CulturalIndexDirection {
    Outwards = "Outwards",
    Stable = "Stable",
    Inwards = "Inwards",
}
export class CulturalIndex extends BaseEntity<CulturalIndex> implements ICulturalMeasurementEntity {
    public culturalIndexId!: number;
    public organisationId!: number;
    public personId!: number;
    public authorId!: number;
    public creationDate!: Date;
    public value!: number;
    public direction!: CulturalIndexDirection;
    public notes!: string;

    public author!: Person;
    public organisation!: Organisation;
    public person!: Person;

    @EntityExtensions(CulturalIndexExtensions)
    public extensions!: CulturalIndexExtensions;
}

export const CulturalIndexBreezeModel = new BreezeModelBuilder("CulturalIndex", CulturalIndex)
    .hasSource("CulturalIndexes")
    .isOrganisationEntity()
    .withIdField()
    .orderByDesc("creationDate")
    .withActivePath("person.connections")
    .persistChangedEntity()
    .withGroupByField("personId")
    .withDateField("creationDate")
    .alwaysFetchingNavigationProperty("person")
    .alwaysFetchingNavigationProperty("author")
    .build();
