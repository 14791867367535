<ng-container *ngIf="orgSupplementaryData">
    <dx-check-box [value]="useCustomWelcomeEmail"
                  (valueChange)="onCustomMessageChecked($any($event))"
                  text="Use custom welcome email"></dx-check-box>

    <div *ngIf="useCustomWelcomeEmail">
        <h3 class="my-3">Welcome email message</h3>
        <adapt-html-editor [(contents)]="orgSupplementaryData.customWelcomeEmail"></adapt-html-editor>
        <div class="mt-3 alert alert-light">
            <b>{{"{{ONAME}}"}}</b> is the name of your organisation.
            <br>
            <b>{{"{{SNAME}}"}}</b> is the sender name of the welcome email.
        </div>
    </div>

    <div class="mt-2">
        <button adaptButtonIcon="view"
                [adaptBlockingClick]="previewEmail">Preview welcome email</button>
    </div>
</ng-container>
