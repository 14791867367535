import { Injectable, Injector } from "@angular/core";
import { CulturalIndex, CulturalIndexDirection } from "@common/ADAPT.Common.Model/organisation/cultural-index";
import { CulturalIndexConfigurationBreezeModel } from "@common/ADAPT.Common.Model/organisation/cultural-index-configuration";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import { CulturalLeadershipCatchupStatus } from "app/features/culture/cultural-leadership/cultural-leadership-catchup-status.enum";
import { ICulturalLeadershipPersonalAnalysisData } from "app/features/culture/cultural-leadership/cultural-leadership-personal-analysis-data.interface";
import { lastValueFrom } from "rxjs";
import { CulturalLeadershipFrameworkService, ICulturalLeadershipOverdueData } from "../../culture/cultural-leadership/cultural-leadership-framework.service";
import { ICulturalLeadershipMeasurementAnalysisFilter } from "../../culture/cultural-leadership/cultural-leadership-measurement-analysis-filter.interface";
import { CulturalIndexAnalysisDataService } from "./cultural-index-analysis-data.service";
import { CulturalIndexAuthService } from "./cultural-index-auth.service";
import { CulturalIndexDataService } from "./cultural-index-data.service";

export interface ICulturalIndexChartData {
    value: number;
    creationDate: Date;
    culturalIndex: CulturalIndex;
    min: number;
    max: number;
    index: number;
}

export interface ICulturalIndexDirection {
    id: CulturalIndexDirection;
    label: string;
    state: string;
}

export interface ICulturalIndexStatistics {
    totalPeople: number;

    missingCulturalIndexes: Person[];
    overdueCatchups: ICulturalLeadershipOverdueData[];

    averageCulturalIndex: number;

    category1Count: number;
    category2Count: number;
    category3Count: number;
    category4Count: number;
}

@Injectable({
    providedIn: "root",
})
@Autobind
export class CulturalIndexService extends BaseOrganisationService {
    public directions: ICulturalIndexDirection[] = [
        {
            id: CulturalIndexDirection.Outwards,
            label: "Outward",
            state: "moving outwards",
        },
        {
            id: CulturalIndexDirection.Stable,
            label: "Stable",
            state: "stable",
        },
        {
            id: CulturalIndexDirection.Inwards,
            label: "Inward",
            state: "moving inwards",
        },
    ];

    public constructor(
        injector: Injector,
        private culturalIndexDataService: CulturalIndexDataService,
        private culturalIndexAnalysisDataService: CulturalIndexAnalysisDataService,
        private culturalLeadershipFrameworkService: CulturalLeadershipFrameworkService,
        private authorisationService: AuthorisationService,
    ) {
        super(injector);
    }

    protected organisationInitialisationActions() {
        return [this.requiredActions()];
    }

    private async requiredActions() {
        try {
            await this.authorisationService.promiseToVerifyAccess(CulturalIndexAuthService.ReadAnyCulturalIndex);
            await this.culturalLeadershipFrameworkService.promiseToGetCulturalLeadershipConfiguration();
            await this.promiseToGetCulturalIndexConfiguration();
        } catch {
            // no-op
        }
    }

    public async promiseToGetCulturalIndexConfiguration() {
        const configurations = await lastValueFrom(this.commonDataService.getAll(CulturalIndexConfigurationBreezeModel));
        return ArrayUtilities.getSingleFromArray(configurations)!;
    }

    public promiseToGetLatestCulturalIndexForPerson(personId: number) {
        return this.culturalIndexDataService.promiseToGetLatestCulturalIndexForPerson(personId);
    }

    public promiseToGetAllCulturalIndexesForPerson(personId: number) {
        return this.culturalIndexDataService.promiseToGetAllCulturalIndexesForPerson(personId);
    }

    public async promiseToGetCulturalIndexAnalysisData(initialFilter?: ICulturalLeadershipMeasurementAnalysisFilter, prefetchAllData = false) {
        return this.culturalIndexAnalysisDataService.promiseToGetCulturalIndexAnalysisData(initialFilter, prefetchAllData);
    }

    public getDirectionState(id: CulturalIndexDirection) {
        for (const direction of this.directions) {
            if (direction.id === id) {
                return direction.state;
            }
        }

        return null;
    }

    public generateCulturalIndexStatistics(data: ICulturalLeadershipPersonalAnalysisData[]) {
        const missingCulturalIndexes: any[] = [];
        let total = 0.0;
        let category1Count = 0;
        let category2Count = 0;
        let category3Count = 0;
        let category4Count = 0;
        const overdueCatchups: ICulturalLeadershipOverdueData[] = [
            {
                status: CulturalLeadershipCatchupStatus.Overdue,
                label: "Overdue",
                value: 0,
            }, {
                status: CulturalLeadershipCatchupStatus.Due,
                label: "Due Now",
                value: 0,
            }, {
                status: CulturalLeadershipCatchupStatus.OnTime,
                label: "On Time",
                value: 0,
            },
        ];

        for (const personalData of data) {
            if (!personalData.culturalIndex) {
                missingCulturalIndexes.push(personalData.person);
                overdueCatchups[0].value++;
            } else {
                total += personalData.culturalIndex.value;

                if (personalData.culturalIndex.extensions.isCategory1()) {
                    category1Count++;
                } else if (personalData.culturalIndex.extensions.isCategory2()) {
                    category2Count++;
                } else if (personalData.culturalIndex.extensions.isCategory3()) {
                    category3Count++;
                } else {
                    category4Count++;
                }

                if (personalData.catchupStatus === CulturalLeadershipCatchupStatus.Overdue) {
                    overdueCatchups[0].value++;
                } else if (personalData.catchupStatus === CulturalLeadershipCatchupStatus.Due) {
                    overdueCatchups[1].value++;
                } else {
                    overdueCatchups[2].value++;
                }
            }
        }

        return {
            totalPeople: data.length,
            missingCulturalIndexes,
            overdueCatchups,
            averageCulturalIndex: total / (data.length - missingCulturalIndexes.length),
            category1Count,
            category2Count,
            category3Count,
            category4Count,
        } as ICulturalIndexStatistics;
    }
}
