<adapt-dialog [maxWidth]="1000"
              [padding]="false">
    <h3 adapt-dialog-title>Edit resilient business goals</h3>

    <dx-tab-panel adapt-dialog-content
                  class="edit-rb-goals-dialog"
                  [items]="tabs"
                  tabsPosition="left"
                  [swipeEnabled]="false"
                  data-test="edit-rb-goals-tab-panel">
        <div *dxTemplate="let part of 'title'">
            {{part.title}}
        </div>
        <div *dxTemplate="let part of 'item'">
            <adapt-html-editor #focus
                               [(contents)]="part.content"></adapt-html-editor>
        </div>
    </dx-tab-panel>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="allUnmodified"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
