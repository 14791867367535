<adapt-toolbar-inactive [entity]="businessModel?.valueStream"
                        type="value stream">
</adapt-toolbar-inactive>

<div>
    <div class="row">
        <div class="col-12">
            <div [ngSwitch]="synopsis.editing"
                 class="card">
                <div class="card-header">
                    <h2 class="card-title">Synopsis</h2>
                    <span class="fal fa-info-circle"
                          adaptTooltip="A basic overview of the Business Model."></span>
                    <div class="card-header-buttons px-1">
                        <ng-container *adaptIfAuthorised="editTier1Architecture">
                            <button *ngSwitchCase="false"
                                    adaptButton="edit"
                                    (click)="synopsis.toggleEditing()"
                                    [disabled]="canEdit"></button>
                        </ng-container>
                        <button *ngSwitchCase="true"
                                adaptButton="save"
                                [adaptBlockingClick]="synopsis.saveChanges"
                                [disabled]="!hasChanges()"></button>
                        <button *ngSwitchCase="true"
                                adaptButton="cancel"
                                [adaptBlockingClick]="synopsis.discardChanges"
                                [disabled]="!canEdit"></button>
                    </div>
                </div>
                <div class="card-body">
                    <div *ngSwitchCase="true">
                        <adapt-html-editor [(contents)]="businessModel!.synopsis">
                        </adapt-html-editor>
                    </div>
                    <div *ngSwitchCase="false"
                         [ngSwitch]="!!businessModel?.synopsis">
                        <div *ngSwitchCase="true"
                             [froalaView]="businessModel?.synopsis"></div>
                        <div *ngSwitchCase="false">No synopsis defined.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <div [ngSwitch]="pricing.editing"
                 class="card">
                <div class="card-header">
                    <h2 class="card-title">Pricing</h2>
                    <span class="fal fa-info-circle"
                          adaptTooltip="Describes the pricing strategy used to deliver mutual value, e.g. subscription, time-based etc..."></span>
                    <div class="card-header-buttons px-1">
                        <ng-container *adaptIfAuthorised="editTier1Architecture">
                            <button *ngSwitchCase="false"
                                    adaptButton="edit"
                                    (click)="pricing.toggleEditing()"
                                    [disabled]="canEdit"></button>
                        </ng-container>
                        <button *ngSwitchCase="true"
                                adaptButton="save"
                                [adaptBlockingClick]="pricing.saveChanges"
                                [disabled]="!hasChanges()"></button>
                        <button *ngSwitchCase="true"
                                adaptButton="cancel"
                                [adaptBlockingClick]="pricing.discardChanges"
                                [disabled]="!canEdit"></button>
                    </div>
                </div>
                <div class="card-body">
                    <div *ngSwitchCase="true">
                        <adapt-html-editor [(contents)]="businessModel!.pricing">
                        </adapt-html-editor>
                    </div>
                    <div *ngSwitchCase="false"
                         [ngSwitch]="!!businessModel?.pricing">
                        <div *ngSwitchCase="true"
                             [froalaView]="businessModel?.pricing"></div>
                        <div *ngSwitchCase="false">No pricing defined.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div [ngSwitch]="customerValueProposition.editing"
                 class="card">
                <div class="card-header">
                    <h2 class="card-title">Customer value proposition</h2>
                    <span class="fal fa-info-circle"
                          adaptTooltip="Describes the value provided to the customer(s)."></span>
                    <div class="card-header-buttons px-1">
                        <ng-container *adaptIfAuthorised="editTier1Architecture">
                            <button *ngSwitchCase="false"
                                    adaptButton="edit"
                                    (click)="customerValueProposition.toggleEditing()"
                                    [disabled]="canEdit"></button>
                        </ng-container>
                        <button *ngSwitchCase="true"
                                adaptButton="save"
                                [adaptBlockingClick]="customerValueProposition.saveChanges"
                                [disabled]="!hasChanges()"></button>
                        <button *ngSwitchCase="true"
                                adaptButton="cancel"
                                [adaptBlockingClick]="customerValueProposition.discardChanges"
                                [disabled]="!canEdit"></button>
                    </div>
                </div>
                <div class="card-body">
                    <div *ngSwitchCase="true">
                        <adapt-html-editor [(contents)]="businessModel!.customerValueProposition">
                        </adapt-html-editor>
                    </div>
                    <div *ngSwitchCase="false"
                         [ngSwitch]="!!businessModel?.customerValueProposition">
                        <div *ngSwitchCase="true"
                             [froalaView]="businessModel?.customerValueProposition"></div>
                        <div *ngSwitchCase="false">No customer value proposition defined.</div>
                    </div>
                </div>
            </div>
            <div [ngSwitch]="organisationValueProposition.editing"
                 class="card">
                <div class="card-header">
                    <h2 class="card-title">Value for the organisation</h2>
                    <span class="fal fa-info-circle"
                          adaptTooltip="Describes the value provided to the organisation."></span>
                    <div class="card-header-buttons px-1">
                        <ng-container *adaptIfAuthorised="editTier1Architecture">
                            <button *ngSwitchCase="false"
                                    adaptButton="edit"
                                    (click)="organisationValueProposition.toggleEditing()"
                                    [disabled]="canEdit"></button>
                        </ng-container>
                        <button *ngSwitchCase="true"
                                adaptButton="save"
                                [adaptBlockingClick]="organisationValueProposition.saveChanges"
                                [disabled]="!hasChanges()"></button>
                        <button *ngSwitchCase="true"
                                adaptButton="cancel"
                                [adaptBlockingClick]="organisationValueProposition.discardChanges"
                                [disabled]="!canEdit"></button>
                    </div>
                </div>
                <div class="card-body">
                    <div *ngSwitchCase="true">
                        <adapt-html-editor [(contents)]="businessModel!.organisationValueProposition">
                        </adapt-html-editor>
                    </div>
                    <div *ngSwitchCase="false"
                         [ngSwitch]="!!businessModel?.organisationValueProposition">
                        <div *ngSwitchCase="true"
                             [froalaView]="businessModel?.organisationValueProposition"></div>
                        <div *ngSwitchCase="false">No organisation value proposition defined.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
