import { Injectable } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { SignalRHub } from "@common/lib/signalr-provider/signalr-hub";
import { ISignalRSubscriptionHandler } from "@common/lib/signalr-provider/signalr-subscription-handler.interface";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { cleanupEmittedValues } from "@common/lib/utilities/rxjs-utilities";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { defer, Subject } from "rxjs";
import { filter, finalize, map, switchMap } from "rxjs/operators";

enum ActivityFeedServerHubMethod {
    SubscribeToOrganisationActivity = "subscribeToOrganisationActivity",
    UnsubscribeFromOrganisationActivity = "unsubscribeFromOrganisationActivity",
    SubscribeToTeamActivity = "subscribeToTeamActivity",
    UnsubscribeFromTeamActivity = "unsubscribeFromTeamActivity",
    SubscribeToPersonalActivity = "subscribeToPersonalActivity",
    UnsubscribeFromPersonalActivity = "unsubscribeFromPersonalActivity",
}

@Injectable({
    providedIn: "root",
})
export class ActivityFeedSignalRHub extends SignalRHub {
    public static readonly Name = "activityFeedHub";

    private organisationActivitySubscriptionHandler: ISignalRSubscriptionHandler<[number]>;
    private newOrganisationActivity$ = new Subject<void>();

    private teamActivitySubscriptionHandler: ISignalRSubscriptionHandler<[number]>;
    private newTeamActivity$ = new Subject<number>();

    private personalActivitySubscriptionHandler: ISignalRSubscriptionHandler<[number, number]>;
    private newPersonalActivity$ = new Subject<number>();

    public constructor(
        private organisationService: OrganisationService,
    ) {
        super(ActivityFeedSignalRHub.Name);
        this.organisationActivitySubscriptionHandler = this.createSubscriptionHandler(
            ActivityFeedServerHubMethod.SubscribeToOrganisationActivity,
            ActivityFeedServerHubMethod.UnsubscribeFromOrganisationActivity,
        );
        this.teamActivitySubscriptionHandler = this.createSubscriptionHandler(
            ActivityFeedServerHubMethod.SubscribeToTeamActivity,
            ActivityFeedServerHubMethod.UnsubscribeFromTeamActivity,
        );
        this.personalActivitySubscriptionHandler = this.createSubscriptionHandler(
            ActivityFeedServerHubMethod.SubscribeToPersonalActivity,
            ActivityFeedServerHubMethod.UnsubscribeFromPersonalActivity,
        );
    }

    public getHandlingMethods() {
        return {
            newOrganisationActivity: () => this.newOrganisationActivity$.next(),
            newTeamActivity: (teamId: number) => this.newTeamActivity$.next(teamId),
            newPersonalActivity: (personId: number) => this.newPersonalActivity$.next(personId),
        };
    }

    /** Creates a hot observable which will emit each time new activity is generated
     * for the specified organisation
     */
    public onOrganisationActivity() {
        return this.organisationService.organisation$.pipe(
            filter(ObjectUtilities.createIsInstanceFilter(Organisation)),
            map((organisation) => organisation.organisationId),
            switchMap((orgId) => this.promiseToSubscribeToOrganisationActivity(orgId), (orgId) => orgId),
            cleanupEmittedValues((orgId) => this.promiseToUnsubscribeFromOrganisationActivity(orgId)),
            switchMap(() => this.newOrganisationActivity$),
        );
    }

    /** Creates a hot observable which will emit each time new activity is generated
     * for the specified team
     */
    public onTeamActivity(teamId: number) {
        return defer(() => this.promiseToSubscribeToTeamActivity(teamId)).pipe(
            switchMap(() => this.newTeamActivity$),
            filter((teamIdWithActivity) => teamId === teamIdWithActivity),
            finalize(() => this.promiseToUnsubscribeFromTeamActivity(teamId)),
        );
    }

    /** Creates a hot observable which will emit each time new activity is generated
     * in the current organisation for the specified person.
     */
    public onPersonalActivity(person: Person) {
        const personId = person.personId;
        return this.organisationService.organisation$.pipe(
            filter(ObjectUtilities.createIsInstanceFilter(Organisation)),
            map((organisation) => organisation.organisationId),
            switchMap((orgId) => this.promiseToSubscribeToPersonalActivity(orgId, personId), (orgId) => orgId),
            cleanupEmittedValues((orgId) => this.promiseToUnsubscribeFromPersonalActivity(orgId, personId)),
            switchMap(() => this.newPersonalActivity$),
            filter((personIdWithActivity) => personId === personIdWithActivity),
        );
    }

    private promiseToSubscribeToOrganisationActivity(organisationId: number) {
        return this.organisationActivitySubscriptionHandler.promiseToSubscribe(organisationId);
    }

    private promiseToUnsubscribeFromOrganisationActivity(organisationId: number) {
        this.organisationActivitySubscriptionHandler.promiseToUnsubscribe(organisationId);
    }

    private promiseToSubscribeToTeamActivity(teamId: number) {
        return this.teamActivitySubscriptionHandler.promiseToSubscribe(teamId);
    }

    private promiseToUnsubscribeFromTeamActivity(teamId: number) {
        this.teamActivitySubscriptionHandler.promiseToUnsubscribe(teamId);
    }

    private promiseToSubscribeToPersonalActivity(organisationId: number, personId: number) {
        return this.personalActivitySubscriptionHandler.promiseToSubscribe(organisationId, personId);
    }

    private promiseToUnsubscribeFromPersonalActivity(organisationId: number, personId: number) {
        this.personalActivitySubscriptionHandler.promiseToUnsubscribe(organisationId, personId);
    }
}
