<adapt-dialog [maxWidth]="700">
    <h3 adapt-dialog-title>Add key function</h3>

    <ng-container adapt-dialog-content>
        <adapt-edit-key-function-details #focus
                                         [keyFunction]="keyFunction"
                                         (entityModified)="addEntitiesToConfirm($event)"></adapt-edit-key-function-details>

        <div class="form-group">
            <label>Purpose</label>
            <adapt-html-editor [(contents)]="purpose"
                               data-test="key-function-purpose-input"></adapt-html-editor>
        </div>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [discardConfirmationInstance]="instance">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
