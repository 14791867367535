<ng-container *adaptLoadingUntilFirstEmit="let outstandingSurveyResponses of outstandingSurveyResponses$">
    <adapt-dashboard-element *ngIf="outstandingSurveyResponses!.length > 0"
                             name="Outstanding Survey{{outstandingSurveyResponses!.length > 1 ? 's' : ''}}">
        <div *ngFor="let outstandingSurveyResponse of outstandingSurveyResponses; let first = first"
             [ngClass]="first ? 'mt-3' : 'mt-4'">
            <h4>{{getSurveyHeader(outstandingSurveyResponse.survey)}}</h4>
            <adapt-personal-ongoing-survey [outstandingSurveyResponse]="outstandingSurveyResponse"></adapt-personal-ongoing-survey>
        </div>
    </adapt-dashboard-element>
</ng-container>
