<adapt-render-organisation-map [entityTemplates]="{KeyFunction: keyFunctionTemplate, Team: teamTemplate}"
                               [globalZoneLocationTemplate]="globalZoneLocationTemplate"
                               [showGlobalZoneLocation]="showGlobalZone$ | async"
                               globalZoneLocationLabel="Global Teams"
                               [filterTeams]="filterTeams"
                               (loaded)="loaded.emit()"></adapt-render-organisation-map>

<ng-template #keyFunctionTemplate
             let-keyFunction>
    <adapt-key-function-card [keyFunction]="keyFunction"></adapt-key-function-card>
    <div *ngIf="showTeams"
         class="location-sub-list"
         [ngClass]="getZoneClass(keyFunction.zone)"
         data-test="teams-card">
        <ng-container *adaptLoadingUntilFirstEmit="let teams of getKeyFunctionTeams(keyFunction)">
            <adapt-link-team *ngFor="let team of teams"
                             [team]="team"
                             [showIcon]="true"
                             [showLabels]="true"
                             [showChildren]="true"
                             [filterTeams]="filterTeams"
                             [showAllChildren]="true"></adapt-link-team>
        </ng-container>
    </div>
    <div *ngIf="showSystems"
         class="location-sub-list"
         [ngClass]="getZoneClass(keyFunction.zone)">
        <ng-container *adaptLoadingUntilFirstEmit="let systems of getKeyFunctionSystems(keyFunction)">
            <adapt-link-system *ngFor="let system of systems"
                               [system]="system"
                               [showLabels]="true"></adapt-link-system>
        </ng-container>
    </div>
</ng-template>

<ng-template #teamTemplate
             let-team>
    <adapt-team-card [team]="team"
                     [filterTeams]="filterTeams"
                     [showChildren]="true"></adapt-team-card>
    <div *ngIf="showSystems"
         class="location-sub-list"
         [ngClass]="getZoneClass()">
        <ng-container *adaptLoadingUntilFirstEmit="let systems of getTeamSystems(team)">
            <adapt-link-system *ngFor="let system of systems"
                               [system]="system"
                               [showLabels]="true"></adapt-link-system>
        </ng-container>
    </div>
</ng-template>

<ng-template #globalZoneLocationTemplate>
    <ul *adaptLoadingUntilFirstEmit="let globalZoneTeams of globalZoneTeams$">
        <li *ngFor="let team of globalZoneTeams"
            class="global-teams">
            <adapt-link-team [team]="team"
                             [showIcon]="true"
                             [showLabels]="true"
                             [showChildren]="true"
                             [showAllChildren]="true"></adapt-link-team>
        </li>
    </ul>
</ng-template>

<ng-template #noKeyFunctionsTemplate>
    <adapt-call-to-action [learnMoreUrl]="learnMoreUrl">
        <adapt-call-to-action-title [iconClass]="keyFunctionIconClass">
            Key Functions
        </adapt-call-to-action-title>

        <p>
            The ADAPT approach starts with the leadership team identifying and agreeing what
            the Key Functions are in your business.
        </p>
        <p>
            A Key Function is a set of accountabilities and processes carried out within an
            area of the business.
        </p>
        <p>
            Use the ADAPT Platform to capture the following details for each of your Key
            Functions:
        </p>
        <ul class="list-content">
            <li><b>Name:</b> Get agreement as to what this function will be named.</li>
            <li><b>Leader:</b> Who is the Leader</li>
            <li><b>Description:</b> Describe the function clearly for everyone in the business</li>
            <li><b>Accountabilities:</b> Limit to 4-5 key accountabilities. What needs to be delivered from this function</li>
            <li><b>Flow:</b> A sequence of high-level processes of this function that deliver value</li>
            <li><b>People/roles/teams:</b> What are the roles and the teams that do the work in this function</li>
            <li><b>Systems:</b> Identifying systems is important as they can play an important role in managing the work of the function
            </li>
        </ul>

        <button *ngIf="canEditTier1"
                adaptCallToActionButton
                adaptButtonIcon="add"
                [adaptBlockingClick]="addKeyFunction">
            Add key function
        </button>
    </adapt-call-to-action>
</ng-template>
