<adapt-dialog [maxWidth]="850">
    <h3 adapt-dialog-title>Edit Value Stream</h3>
    <form adapt-dialog-content
          role="form">
        <div class="form-group row">
            <label class="col-md-3"
                   for="inputName">Name</label>
            <div class="col-md-9">
                <dx-text-box id="inputName"
                             [adaptValidateEntity]="valueStream"
                             adaptValidateEntityProperty="name"
                             [(value)]="valueStream.name"
                             (onValueChanged)="updateRelatedEntities()"
                             placeholder="Enter value stream name"
                             data-test="value-stream-name-input"></dx-text-box>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3"
                   for="roleAllocation">{{vsTslRole?.label}}</label>
            <adapt-allocate-tier1-role id="roleAllocation"
                                       class="col-md-9"
                                       [role]="vsTslRole"
                                       data-test="value-stream-tsl-input"></adapt-allocate-tier1-role>
        </div>
        <div class="form-group row"
             *ngIf="vsPurpose">
            <label class="col-md-3"
                   for="inputPurpose">
                Purpose
                <i class="fal fa-info-circle"
                   adaptTooltip="The economic purpose of the value stream."></i>
            </label>
            <adapt-html-editor id="inputPurpose"
                               class="col-md-9"
                               [(contents)]="vsPurpose.content"
                               data-test="value-stream-purpose-input"></adapt-html-editor>
        </div>
    </form>
    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="valueStream.entityAspect.hasValidationErrors"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
