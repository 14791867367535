<adapt-dialog [maxWidth]="850">
    <h3 adapt-dialog-title>{{title}}</h3>

    <!-- Value -->
    <div adapt-dialog-content>
        <div class="form-group">
            <label for="ciValue">Rate your cultural index</label>
            <adapt-ci-range id="ciValue"
                            [culturalIndex]="culturalIndex"
                            [isDisabled]="false"></adapt-ci-range>
        </div>

        <!-- Direction -->
        <div class="form-group form-inline">
            <label for="ciDirection">My orbit is </label>
            <dx-select-box id="ciDirection"
                           data-test="cultural-index-direction"
                           [(value)]="culturalIndex.direction"
                           [items]="directions"
                           displayExpr="state"
                           valueExpr="id">
            </dx-select-box>
        </div>

        <!-- Notes -->
        <div class="form-group">
            <adapt-html-editor [(contents)]="culturalIndex.notes"
                               [required]="true"
                               data-test="cultural-index-notes-input"
                               (isValidChange)="validate()"></adapt-html-editor>
        </div>

        <!-- Date & Time -->
        <div class="form-group form-inline">
            <label for="creationDate">Recorded on:</label>
            <dx-date-box type="date"
                         data-test="cultural-index-date"
                         [displayFormat]="DateFormats.globalize.short"
                         [value]="culturalIndex.creationDate"
                         (valueChange)="onDateChange($any($event))"></dx-date-box>
        </div>
    </div>

    <div adapt-dialog-footer
         class="ms-auto">
        <button adaptButton="saveAndClose"
                [disabled]="disabled"
                [adaptBlockingClick]="saveAndClose"></button>
        <button adaptButton="cancel"
                (click)="cancel()"></button>
    </div>
</adapt-dialog>
