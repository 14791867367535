/* eslint-disable import/order */

/*
    Vendor files to import globally
    As time goes on we will hopefully be eliminate these and import them in the places
    where they are used (e.g. import "angular-froala" in the htmlEditor component)
    but there are a few things that need to happen bofore this can occur.
    1. All projects need to be Hybrid (so we can add vendor imports in common modules code)
    2. Some components will need to be migrated to Angular as the AngularJs integrations
        only support a global import like this.

    The reason all imports are not done like this is that when you import a file like this
    the scripts can detect its a module import (as opposed to a UMD file) and will adjust
    to avoid polluting the window object.
    Other scripts don't and assume a dependency is on the window which then fails.
    We leave such scripts in the "scripts" property in angular.json.
    Don't do this if you can avoid it as we have to duplicate the files in the test config too.
*/
import "froala-editor/js/froala_editor.min.js";
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/code_view.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/js/plugins/draggable.min.js";
import "froala-editor/js/plugins/font_family.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/fullscreen.min.js";
import "froala-editor/js/plugins/help.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/image_manager.min.js";
import "froala-editor/js/plugins/inline_style.min.js";
import "froala-editor/js/plugins/line_breaker.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/paragraph_style.min.js";
import "froala-editor/js/plugins/quote.min.js";
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/js/plugins/url.min.js";
import "froala-editor/js/plugins/video.min.js";
import "froala-editor/js/plugins/word_paste.min.js";
import "signalr";
import "moment";
// TODO: remove once we remove JQuery
import "devextreme/integration/jquery";
