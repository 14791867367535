import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { LabelLocation } from "@common/ADAPT.Common.Model/organisation/label-location";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { ProcessStep } from "@common/ADAPT.Common.Model/organisation/process-step";
import { ProcessStepGuidanceMaterial } from "@common/ADAPT.Common.Model/organisation/process-step-guidance-material";
import { ProcessStepTypeMetadata } from "@common/ADAPT.Common.Model/organisation/process-step-type";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { dxTabPanelItem } from "devextreme/ui/tab_panel";
import { switchMap } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
    selector: "adapt-edit-process-step-dialog",
    templateUrl: "./edit-process-step-dialog.component.html",
    styleUrls: ["./edit-process-step-dialog.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class EditProcessStepDialogComponent extends BaseDialogWithDiscardConfirmationComponent<ProcessStep> {
    public readonly dialogName = "EditProcessStep";
    public entitiesToConfirm: IBreezeEntity[];

    public title: string;

    public tabPanelItems?: dxTabPanelItem[];

    private processStepGuidanceMaterials: ProcessStepGuidanceMaterial[] = [];

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public processStep: ProcessStep,
    ) {
        super();

        if (!this.processStep.supplementaryData) {
            throw new Error("Process Step Supplementary data should be created before editing");
        }

        const action = processStep.entityAspect.entityState.isAdded() ? "Add" : "Edit ";
        this.title = `${action} ${ProcessStepTypeMetadata.Label[processStep.type]}`;

        this.entitiesToConfirm = [processStep];
        if (processStep.supplementaryData) {
            this.entitiesToConfirm.push(processStep.supplementaryData);
        }

        if (this.processStep.extensions.isRoleTask) {
            this.tabPanelItems = [
                { title: "Details", template: "detailsTemplate" },
                { title: "Guidance Questions", template: "guidanceQuestionsTemplate" },
                { title: "Guidance Materials", template: "guidanceMaterialsTemplate" },
            ];
        }
    }

    public roleFilter(role: Role) {
        return !role.teamId;
    }

    public guidanceMaterialChanged(processStepGuidanceMaterials: ProcessStepGuidanceMaterial[]) {
        // remove previously added gm entities
        this.processStepGuidanceMaterials.forEach((processStepGuidanceMaterial) => {
            ArrayUtilities.removeElementFromArray<IBreezeEntity>(processStepGuidanceMaterial, this.entitiesToConfirm);
        });
        processStepGuidanceMaterials.forEach((processStepGuidanceMaterial) => {
            this.entitiesToConfirm.push(processStepGuidanceMaterial);

            if (processStepGuidanceMaterial.guidanceMaterial?.entityAspect.entityState.isAddedModifiedOrDeleted()
                && !this.entitiesToConfirm.includes(processStepGuidanceMaterial.guidanceMaterial)) {
                this.entitiesToConfirm.push(processStepGuidanceMaterial.guidanceMaterial);
            }
        });
        this.processStepGuidanceMaterials = processStepGuidanceMaterials;
    }

    @Autobind
    public saveAndClose() {
        const suppData = this.processStep.supplementaryData ?? undefined;
        // remove suppData if empty
        return this.commonDataService.remove(suppData?.description || suppData?.guidanceQuestions ? undefined : suppData).pipe(
            switchMap(() => this.commonDataService.saveEntities(this.entitiesToConfirm)),
            tap(() => this.resolve(this.processStep)),
        );
    }

    @Autobind
    public isNotCurrentProcessMap(processMap: ProcessMap) {
        return this.processStep.processMap !== processMap;
    }

    public setLinkedProcessMap(processMap: ProcessMap) {
        this.processStep.linkedProcessMap = processMap;
        this.processStep.entityAspect.validateEntity();
    }

    public onLabelLocationsChanged(labelLocations: LabelLocation[]) {
        this.entitiesToConfirm.push(...labelLocations);
        this.entitiesToConfirm = ArrayUtilities.distinct(this.entitiesToConfirm);
    }
}
