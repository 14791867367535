import { Component, Inject, Injector, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { NonParticipantUserTypes } from "@common/ADAPT.Common.Model/embed/user-type";
import { LabelLocation } from "@common/ADAPT.Common.Model/organisation/label-location";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveType } from "@common/ADAPT.Common.Model/organisation/objective-type";
import { IEntityWithRequiredTeam } from "@common/ADAPT.Common.Model/organisation/team-entity.interface";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { PersonFlag } from "@common/ADAPT.Common.Model/person/person-flag.enum";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { EntityPersistentService } from "@common/lib/data/entity-persistent.service";
import { PersistableDialog } from "@common/lib/data/persistable-dialog.decorator";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { GuidedTourService } from "@common/lib/guided-tour/guided-tour.service";
import { NavigationHierarchyService } from "@common/route/navigation-hierarchy.service";
import { UserService } from "@common/user/user.service";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { PersonFlagService } from "@org-common/lib/person/person-flag.service";
import { of, throwError } from "rxjs";
import { catchError, delay, switchMap, tap } from "rxjs/operators";
import { ObjectiveDialogTour } from "../objective-dialog-tour";
import { ObjectivesService } from "../objectives.service";
import { ObjectivesAuthService } from "../objectives-auth.service";
import { ObjectivesRouteService } from "../objectives-route.service";
import { ObjectivesUiService } from "../objectives-ui.service";
import { SelectSupportingObjectiveComponent } from "../select-supporting-objective/select-supporting-objective.component";

@PersistableDialog("EditObjectiveDialogComponent")
@Component({
    selector: "adapt-edit-objective-dialog",
    templateUrl: "./edit-objective-dialog.component.html",
    styleUrls: ["./edit-objective-dialog.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class EditObjectiveDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Objective> implements OnInit {
    public readonly dialogName = "EditObjective";
    public readonly allowedObjectiveOwnerUserTypes = NonParticipantUserTypes;

    public isSaving = false;
    public isObjectiveValid = false;
    public dueDateTouched = false;
    public titleErrorMessage?: { message: string } = undefined;
    public objectiveTitleChanged = false;
    public changedLabelLocations: LabelLocation[] = [];

    // Cannot use ObjectiveType enum in template
    public readonly ObjectiveTypeEnum = ObjectiveType;
    public isAutoDueDate = false;

    @ViewChild(SelectSupportingObjectiveComponent) private selectSupportingObjectiveComponent?: SelectSupportingObjectiveComponent;

    public constructor(
        private objectivesService: ObjectivesService,
        private objectivesAuthService: ObjectivesAuthService,
        @Inject(ADAPT_DIALOG_DATA) public objective: Objective,
        injector: Injector,
        private navHierarchyService: NavigationHierarchyService,
        private entityPersistentService: EntityPersistentService,
        private guidedTourService: GuidedTourService,
        private dialogService: AdaptCommonDialogService,
        private objectivesUiService: ObjectivesUiService,
        private personFlagService: PersonFlagService,
        private userService: UserService,
        private objectivesRouteService: ObjectivesRouteService,
        private implementationKitService: ImplementationKitService,
    ) {
        super(injector);
    }

    @Autobind
    public filterAssignees(person: Person) {
        return this.objective.teamId
            ? this.objectivesAuthService.personCanEditObjectivesForTeam(person, this.objective as unknown as IEntityWithRequiredTeam)
            : true;
    }

    public get hasChild() {
        return this.objective.childObjectives.length > 0;
    }

    public get isNew() {
        return this.objective.entityAspect.entityState.isAdded();
    }

    public get entitiesToConfirm() {
        return [this.objective, ...this.changedLabelLocations];
    }

    public get hasUnsavedEntity() {
        return this.entityPersistentService.isUnsavedEntity(this.objective) ||
            this.changedLabelLocations.length > 0 && this.changedLabelLocations.some((l) => !l.entityAspect.entityState.isUnchanged());
    }

    public async ngOnInit() {
        this.validateObjective();
        await this.autoUpdateDueDate();

        if (this.isNew && !this.guidedTourService.tourHasRun(ObjectiveDialogTour)) {
            this.guidedTourService.run(ObjectiveDialogTour);
        }

        if (!this.objective.description && this.isNew) {
            this.implementationKitService.getArticle(ImplementationKitArticle.ObjectiveDescriptionPrefilled).pipe(
                delay(0), // without this, froala sometimes won't allow editing of the prefilled content!
                this.takeUntilDestroyed(),
            ).subscribe((article) => this.objective.description = this.implementationKitService.stripArticleEditLinkHeader(article.answer));
        }
    }

    @Autobind
    public save() {
        // take a snapshot of this before saving - to decide if to redirect or not after save
        const isNew = this.isNew;

        this.isSaving = true;
        this.objective.modifiedDate = new Date(); // automatically set modified date
        return this.objectivesService.saveEntities(this.entitiesToConfirm)
            .pipe(
                tap(() => {
                    this.changedLabelLocations = [];
                    if (this.objectiveTitleChanged) {
                        this.navHierarchyService.updateActiveNodeFromUrlIfRouteParamMatchesValue("objectiveId", this.objective.objectiveId);
                    }

                    this.objectivesService.emitObjectiveUpdate(this.objective);
                    this.resolve(this.objective);

                    if (isNew && AdaptClientConfiguration.AdaptProjectName !== AdaptProject.Alto) {
                        // after creation, redirect to edit page for non-alto
                        this.objectivesRouteService.gotoEditObjectivePageRoute(this.objective.objectiveId, this.objective.teamId).subscribe();
                    }
                }),
                catchError((err: AdaptError) => {
                    this.setErrorMessage(err.message);
                    this.isSaving = false;
                    return throwError(() => err);
                }),
            );
    }

    public onPersonChange(person?: Person) {
        this.objective.assigneePerson = person;
        this.validateObjective();
    }

    public validateObjective() {
        if (this.objective && this.objective.entityAspect) {
            this.isObjectiveValid = !this.objective.entityAspect.hasValidationErrors &&
                !!this.objective.assigneePersonId && !!this.objective.title && !!this.objective.title.trim();
            if (this.isObjectiveValid) {
                this.setErrorMessage(undefined);
            }
        }
    }

    public async onObjectiveTypeChanged(objType: ObjectiveType) {
        this.objective.type = objType;
        await this.autoUpdateDueDate();

        if (objType === ObjectiveType.Annual) {
            this.objective.parentObjectiveId = undefined;
        }

        this.selectSupportingObjectiveComponent?.ngOnChanges();
    }

    private async autoUpdateDueDate() {
        if (this.dueDateTouched) {
            return;
        }

        this.isAutoDueDate = await this.objectivesService.autoUpdateDueDate(this.objective);
    }

    public dueDateChanged(event: Date | number | string) {
        if (this.dueDateTouched) {
            this.isAutoDueDate = false;
        }
        this.objective.dueDate = event as Date;
    }

    public useObjectiveWizard() {
        // only show confirmation dialog if description is not empty. Otherwise nothing to lose
        const optionalCreationSwitchPrompt = this.objective?.description?.trim()
            ? this.dialogService.openConfirmationDialog({
                title: "Switching to objective creation wizard...",
                message: `<p>You may be losing the objective description after switching to the wizard.</p>
                <p>Are you sure you want to switch to the objective creation wizard?</p>`,
                confirmButtonText: "Switch to objective creation wizard",
            }) : of(undefined);
        optionalCreationSwitchPrompt.pipe(
            switchMap(() => this.personFlagService.setFlagAndSave(this.userService.getCurrentPersonId()!, PersonFlag.UseLegacyObjectiveCreation, false)),
            switchMap(() => {
                this.close();
                return this.objectivesUiService.openObjectiveCreationWizard(this.objective);
            }),
        ).subscribe();
    }
}
