<ng-container *adaptLoading="!anchor">
    <div class="card avoid-page-break-inside">
        <div class="card-body">
            <div class="row gap-3 justify-content-evenly">
                <div class="col-12 col-xl-3 d-flex align-items-center justify-content-center gap-3">
                    <h2 class="mb-0 text-center">{{ anchor!.name }}</h2>
                    <div *ngIf="isEditing">
                        <button adaptButtonIcon="edit"
                                class="btn btn-link"
                                [adaptBlockingClick]="editAnchor"
                                [adaptBlockingClickParam]="anchor"
                                adaptTooltip="Edit anchor"
                                data-test="edit-anchor-button"></button>
                    </div>
                </div>
                <div class="col-12 col-xl-8 px-xl-5">
                    <div class="row">
                        <div class="col-12 d-flex flex-column">
                            <div class="anchor-content flex-grow-1">
                                <div [froalaView]="anchor!.description"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
