import { Component, Inject } from "@angular/core";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { SystemComponent } from "@common/ADAPT.Common.Model/organisation/system-component";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { PersistableDialog } from "@common/lib/data/persistable-dialog.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { switchMap } from "rxjs";
import { tap } from "rxjs/operators";

export interface IEditProcessMapDialogData {
    processMap: ProcessMap;
    systemComponent: SystemComponent; // this is needed to store purpose in the supplementary data
}

@Component({
    selector: "adapt-edit-process-map-dialog",
    templateUrl: "./edit-process-map-dialog.component.html",
})
@PersistableDialog("EditProcessMapDialog")
export class EditProcessMapDialogComponent extends BaseDialogWithDiscardConfirmationComponent<SystemComponent> {
    public readonly dialogName = "EditProcessMap";

    public addMode: boolean;
    public title: string;
    public entitiesToConfirm: IBreezeEntity[];

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public data: IEditProcessMapDialogData,
    ) {
        super();
        this.autoResolveData = data.systemComponent;

        if (!data.systemComponent.supplementaryData) {
            throw new Error("ProcessMap SystemComponentSupplementaryData must be defined before editing");
        }

        this.addMode = data.processMap.entityAspect.entityState.isAdded();
        this.title = this.addMode
            ? "Add task map"
            : "Edit task map";

        this.entitiesToConfirm = [
            data.processMap,
            data.systemComponent,
            data.systemComponent.supplementaryData,
        ];
    }

    public get isValid() {
        return this.data.systemComponent.name
            && !this.entitiesAreUnmodifiedOrInvalid;
    }

    // updating process map name should also update system component name
    // TODO: process map name should be stored in system component (cannot remove process map name yet as we need it for migration)
    //   however, we should be changing all places that uses process map name to system component name
    public onNameChange(processMapName: string) {
        this.data.systemComponent.name = processMapName;
        this.data.systemComponent.extensions.validateComponentName();
    }

    @Autobind
    public saveAndClose() {
        const suppData = this.data.systemComponent.supplementaryData;
        // remove suppData if description is not set
        return this.commonDataService.remove(suppData?.description ? undefined : suppData).pipe(
            switchMap(() => this.commonDataService.saveEntities(this.entitiesToConfirm)),
            tap(() => this.resolve(this.data.systemComponent)),
        );
    }
}
