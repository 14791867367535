/* eslint-disable max-classes-per-file */
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { Logger } from "@common/lib/logger/logger";
import { Predicate } from "breeze-client";
import { BaseEntity } from "../../ADAPT.Common.Model/base-entity";
import { ObjectUtilities } from "../utilities/object-utilities";
import { StringUtilities } from "../utilities/string-utilities";
import { ObjectPath } from "../utilities/type-utilities";
import { IBreezeEntity } from "./breeze-entity.interface";

export interface IMethodologyPredicate<T extends IBreezeEntity<T> = any> {
    and(pred: IMethodologyPredicate<T>): IMethodologyPredicate<T>;
    or(pred: IMethodologyPredicate<T>): IMethodologyPredicate<T>;
    not(): IMethodologyPredicate<T>;
    createBreezePredicate(): Predicate | undefined;
    getKey(): string;
    getUniqueEntityNames(resultArray: any): any;

    /**
     * Clones the predicate with an optional additional prefix to the predicate path.
     * @param withPrefix The path without a trailing "."
     */
    clone(withPrefix?: string): IMethodologyPredicate<T>;
}

export type AdaptFilterOp = "==" | "!=" | ">" | "<" | "<=" | ">=" | "any" | "startswith" | "contains" | "in" | "substringof";

export type PathWithoutBaseEntity<T> = [T] extends [never] ? string : ObjectPath<Omit<T, keyof BaseEntity<T>>>;

// Type-check can be bypassed by providing never to T
export class MethodologyPredicate<T extends IBreezeEntity<T> = never> implements IMethodologyPredicate<T> {
    public static log = Logger.getLogger("MethodologyPredicate");

    private ands: IMethodologyPredicate<T>[] = [];
    private ors: IMethodologyPredicate<T>[] = [];
    private negate = false;

    public constructor(
        private entityName?: PathWithoutBaseEntity<T>,
        private comparisonOperator?: AdaptFilterOp,
        private comparisonValue?: any) {
    }

    public getUniqueEntityNames(resultArray: any) {
        if (StringUtilities.isString(this.entityName) && this.entityName.length > 0) {
            if (!resultArray.includes(this.entityName)) {
                resultArray.push(this.entityName);
            }
        }

        if (this.ands.length) {
            this.ands.forEach(appendEntityNames);
        }

        if (this.ors.length) {
            this.ors.forEach(appendEntityNames);
        }

        function appendEntityNames(sub: MethodologyPredicate<T>) {
            sub.getUniqueEntityNames(resultArray);
        }
    }

    public not() {
        this.negate = !this.negate;

        return this;
    }

    @Autobind
    public and(pred: IMethodologyPredicate<T>) {
        if (this.ors.length) {
            throw new Error("This is an OR predicate, use an inner/outer predicate for AND");
        }

        this.ands.push(pred);

        return this;
    }

    @Autobind
    public or(pred: IMethodologyPredicate<T>) {
        if (this.ands.length) {
            throw new Error("This is an AND predicate, use an inner/outer predicate for OR");
        }

        this.ors.push(pred);

        return this;
    }

    public getKey(prefix?: string) {
        let key: string;

        if (!this.entityName && !this.comparisonOperator && ObjectUtilities.isUndefined(this.comparisonValue)) { // blank only if all empty
            key = "";
        } else {
            key = (this.negate
                ? "not"
                : "")
                + String(this.entityName)
                + this.comparisonOperator
                + (this.comparisonValue instanceof MethodologyPredicate
                    ? this.comparisonValue.getKey()
                    : this.comparisonValue);
        }

        if (this.ands.length) {
            this.ands.forEach(appendAndSubKey);
        } else if (this.ors.length) {
            this.ors.forEach(appendOrSubKey);
        }

        if (prefix) {
            // add this prefix as I noticed predicate.getKey() have been used in multiple places to as requestKey.
            // since predicate does not include model identifier, there are clashes, e.g. query for Objectives
            // with teamId == 1 will clash with Survey with teamId == 1 if predicate.getKey() is used - resulting in
            // local get rather than remote.
            key = `${prefix}${key}`;
        }

        return key;

        function appendAndSubKey(sub: MethodologyPredicate<T>) {
            key += "and";
            key += sub.getKey();
        }

        function appendOrSubKey(sub: MethodologyPredicate<T>) {
            key += "or";
            key += sub.getKey();
        }
    }

    public createBreezePredicate(): Predicate | undefined {
        let breezePredicate: Predicate | undefined;

        if (this.entityName && this.comparisonOperator && ObjectUtilities.isDefined(this.comparisonValue)) {
            breezePredicate = new Predicate(
                this.entityName,
                // Typescript isn't smart enough to figure out that string | QueryOp can match
                // with overload of the interface :/ So just cast it anyway
                this.comparisonOperator as AdaptFilterOp,
                this.comparisonValue instanceof MethodologyPredicate
                    ? this.comparisonValue.createBreezePredicate()
                    : getPredicateValue(this.comparisonValue));
        }

        if (this.ands.length) {
            this.ands.forEach(processAnd);
        } else if (this.ors.length) {
            this.ors.forEach(processOr);
        }

        if (this.negate && breezePredicate) {
            breezePredicate = breezePredicate.not();
        }

        return breezePredicate;

        function getPredicateValue(value: any) {
            if (ObjectUtilities.isObject(value)) {
                return value;
            } else {
                // This will resolve issue with breeze predicate trying to resolve the value from the entity first
                // causing the following error
                //  E4000 - [DevExpress.data]: TypeError: (b || "").toLowerCase is not a function. See:
                // in js console when you type 'team' to search for role with the word 'team'. It also applies to
                // search for tool name with 'name' which will always be positive as it is comparing tool.name == tool.name.
                // Note: isLiteral true also works with value null.
                return {
                    value,
                    isLiteral: true,
                };
            }
        }

        function processAnd(andPredicate: MethodologyPredicate<T>) {
            const andBreezePredicate = andPredicate.createBreezePredicate();

            if (andBreezePredicate) {
                if (breezePredicate) {
                    breezePredicate = breezePredicate.and(andBreezePredicate);
                } else {
                    breezePredicate = Predicate.and(andBreezePredicate);
                }
            } else {
                MethodologyPredicate.log.warn("Invalid predicate ignored (" + andPredicate.getKey() + ")");
            }
        }

        function processOr(orPredicate: MethodologyPredicate<T>) {
            const orBreezePredicate = orPredicate.createBreezePredicate();

            if (orBreezePredicate) {
                if (breezePredicate) {
                    breezePredicate = breezePredicate.or(orBreezePredicate);
                } else {
                    breezePredicate = Predicate.or(orBreezePredicate);
                }
            } else {
                MethodologyPredicate.log.warn("Invalid predicate ignored (" + orPredicate.getKey() + ")");
            }
        }
    }

    public clone(withPrefix?: string) {
        const prefix = withPrefix
            ? `${withPrefix}.`
            : "";

        const clonedPredicate = new MethodologyPredicate<T>(
            (prefix + String(this.entityName)) as PathWithoutBaseEntity<T>,
            this.comparisonOperator,
            this.comparisonValue,
        );

        this.ands.forEach((a) => clonedPredicate.and(a.clone(withPrefix)));
        this.ors.forEach((o) => clonedPredicate.or(o.clone(withPrefix)));

        if (this.negate) {
            clonedPredicate.not();
        }

        return clonedPredicate;
    }
}
