<adapt-dialog [maxWidth]="dialogWidth"
              [showHelpButton]="false"
              [expandable]="true">
    <h3 adapt-dialog-title>{{title}}</h3>

    <div adapt-dialog-content>
        <div class="mb-4">
            <h3>Name</h3>
            <dx-text-box #focus
                         [value]="data.systemComponent.name"
                         (valueChange)="onNameChange($event)"
                         [adaptValidateEntity]="data.systemComponent"
                         adaptValidateEntityProperty="name"></dx-text-box>
        </div>
        <div class="mb-4">
            <h3>Document</h3>
            <adapt-select-document *ngIf="useDocumentSelector; else richTextEditor"
                                   [(url)]="data.systemDocument.url"
                                   (urlChange)="validateSystemDocument()"></adapt-select-document>
            <ng-template #richTextEditor>
                <adapt-html-editor [(contents)]="data.systemDocument.content"
                                   forceSize="standard"
                                   [minHeight]="150"></adapt-html-editor>
            </ng-template>
        </div>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [discardConfirmationInstance]="instance"
                                   [saveBlockingClick]="saveAndClose"></adapt-standard-dialog-buttons>
</adapt-dialog>
