import { Component, Inject } from "@angular/core";
import { CulturalIndex, CulturalIndexBreezeModel } from "@common/ADAPT.Common.Model/organisation/cultural-index";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { PersistableDialog } from "@common/lib/data/persistable-dialog.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { DateFormats } from "@common/ux/date-formats";
import { tap } from "rxjs/operators";
import { CulturalIndexService } from "../cultural-index.service";

@Component({
    selector: "adapt-edit-ci-dialog",
    templateUrl: "./edit-ci-dialog.component.html",
})
@PersistableDialog("EditCulturalIndexDialog")
export class EditCulturalIndexDialogComponent extends BaseDialogWithDiscardConfirmationComponent<CulturalIndex> {
    public readonly dialogName = "EditCulturalIndexDialog";
    public title: string;

    public DateFormats = DateFormats;

    public disabled = false;

    public entitiesToConfirm = [this.culturalIndex];

    public directions = this.culturalIndexService.directions;

    constructor(
        @Inject(ADAPT_DIALOG_DATA) public culturalIndex: CulturalIndex,
        protected commonDataService: CommonDataService,
        private culturalIndexService: CulturalIndexService,
    ) {
        super();

        const addMode = this.culturalIndex.entityAspect.entityState.isAdded();
        this.title = (addMode ? "Add " : "Edit ") + CulturalIndexBreezeModel.singularName;
    }

    public validate() {
        this.disabled = this.culturalIndex.entityAspect.hasValidationErrors;
    }

    @Autobind
    public saveAndClose() {
        return this.commonDataService.save().pipe(
            tap(() => this.resolve(this.culturalIndex)),
        );
    }

    public onDateChange(date: Date) {
        this.culturalIndex.creationDate = date;
    }
}
